<template>
  <v-data-table
    :items="items"
    :headers="headers"
    :loading="isFetchingItems"
    :server-items-length="totalCount"
    :hide-default-footer="true"
  >
    <template v-slot:[`item.documentTypeTitle`]="{ item }">
      <span
        v-html="item.studentDocumentTypeTitle ? item.studentDocumentTypeTitle : '&mdash;'"
      ></span>
    </template>

    <template v-slot:[`item.fileName`]="{ item }">
      <v-chip small @click="previewStudentDocument(item)"
        >{{ item.fileName }}
        <v-icon v-if="isPreviewedFile === item.fileHash" small class="ml-2">
          mdi-eye
        </v-icon></v-chip
      >
    </template>

    <template v-slot:[`item.description`]="{ item }">
      <span v-html="item.description ? item.description : '&mdash;'"></span>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <div class="d-flex align-center justify-end" v-if="page === 'edit'">
        <v-btn icon small @click="$emit('editItem', item)">
          <v-icon small> mdi-pencil </v-icon>
        </v-btn>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small icon v-bind="attrs" v-on="on">
              <v-icon small>mdi-dots-horizontal </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="$emit('deleteItem', item)">
              <v-list-item-title
                ><span class="body-2 gray--text text--darken-3"
                  >Silmək</span
                ></v-list-item-title
              >
            </v-list-item>
            <v-list-item @click="downloadStudentDocument(item)">
              <v-list-item-title
                ><span class="body-2 gray--text text--darken-3"
                  >Fayl yüklə</span
                ></v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div class="d-flex align-center justify-end" v-if="page === 'view'">
        <v-btn icon small @click="downloadStudentDocument(item)">
          <v-icon small> mdi-download </v-icon>
        </v-btn>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import API from "@/services";
import fileDownload from "js-file-download";
import bus from "../../../../../../../../event-bus";

export default {
  props: {
    totalCount: {
      type: Number,
      required: true,
    },
    isFetchingItems: {
      type: Boolean,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
    studentId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        { text: "Tipi", value: "studentDocumentTypeTitle", sortable: false },
        { text: "Adı", value: "fileName", sortable: false },
        { text: "Təsviri", value: "description", sortable: false },
        { text: "", value: "actions", sortable: false },
      ],
      options: {},
      isPreviewedFile: null,
    };
  },
  methods: {
    downloadStudentDocument(item) {
      API.downloadStudentDocument(this.studentId, window.btoa(item.fileHash))
        .then((response) => {
          if (response) fileDownload(response, item.fileName);
        })
        .catch(() => {
          bus.$emit("notificationMessage", {
            color: "error",
            text: "Sənədi əldə etmək mümkün olmadı.",
          });
        })
        .finally(() => {});
    },
    previewStudentDocument(item) {
      this.isPreviewedFile = item.fileHash;
      API.previewStudentDocument(this.studentId, window.btoa(item.fileHash))
        .then((response) => {
          const blob = new Blob([response], { type: response.type });
          const link = document.createElement("a");
          link.setAttribute("target", "_blank");
          link.setAttribute("rel", "noopener");
          link.href = URL.createObjectURL(blob);
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(() => {
          bus.$emit("notificationMessage", {
            color: "error",
            text: "Sənədi əldə etmək mümkün olmadı.",
          });
        })
        .finally(() => {
          this.isPreviewedFile = null;
        });
    },
  },
};
</script>
