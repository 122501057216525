<template>
  <v-dialog v-model="value" persistent scrollable max-width="1000px">
    <v-card>
      <div class="py-5 px-4 border-bottom">
        <span
          class="text-h6 gray--text text--darken-3 font-weight-regular"
          v-text="item ? 'Sənədin redaktəsi' : 'Yeni sənəd'"
        >
        </span>
      </div>
      <div class="px-4 pt-4 pb-1">
        <v-form
          id="documentForm"
          ref="documentForm"
          @submit.prevent="validateForm"
          v-model="documentFormValid"
          lazy-validation
        >
          <v-row class="my-0">
            <v-col cols="6" class="mb-3">
              <v-select
                hide-details="auto"
                :items="documentTypes"
                item-text="title"
                item-value="id"
                filled
                dense
                label="Tipi*"
                :rules="rules.studentDocumentTypeId"
                v-model="form.studentDocumentTypeId"
                @change="selectType"
                :disabled="disabled"
              ></v-select>
            </v-col>
            <v-col cols="6" class="mb-3">
              <v-text-field
                v-if="hasFileUploaded"
                hide-details="auto"
                filled
                dense
                label="Sənəd yüklə*"
                prepend-inner-icon="mdi-paperclip"
                prepend-icon
                append-icon="mdi-close"
                readonly
                :value="form.fileName"
                @click:append="removeFileInput"
              >
              </v-text-field>

              <v-file-input
                v-else
                hide-details="auto"
                filled
                dense
                small-chips
                label="Sənəd yüklə*"
                prepend-inner-icon="mdi-paperclip"
                prepend-icon
                :accept="selectedTypes"
                v-model="form.document"
                :rules="rules.document"
              ></v-file-input>
            </v-col>
            <v-col cols="12" class="mb-3">
              <v-textarea
                hide-details="auto"
                filled
                dense
                label="Qeyd"
                no-resize
                rows="2"
                v-model="form.description"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </div>
      <v-card-actions class="py-3 px-4 d-flex justify-end border-top">
        <v-btn color="gray lighten-3" depressed @click="closeDialog">
          Bağla
        </v-btn>
        <v-btn depressed color="success" form="documentForm" type="submit" :loading="isLoading">
          {{ item ? 'Yadda saxla' : 'Yarat' }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import API from '@/services'

export default {
  props: {
    value: {
      type: Boolean
    },
    item: {
      type: Object
    },
    isLoading: {
      type: Boolean
    }
  },
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.form = { ...this.item }
          this.form.studentDocumentTypeId = this.item.studentDocumentTypeId
          this.disabled = true
          this.hasFileUploaded = true
          this.selectType(this.item.studentDocumentTypeId)
        } else {
          this.hasFileUploaded = false
          this.resetForm()
        }
      },
      deep: true
    },
    selectedTypes: {
      handler() {
        this.form.document = null
      }
    }
  },
  data() {
    return {
      form: {
        studentId: null,
        studentDocumentTypeId: null,
        description: null,
        document: null
      },
      rules: {
        studentDocumentTypeId: [v => !!v || 'Tipi tələb olunur'],
        document: [v => !!v || 'Sənəd tələb olunur']
      },
      documentFormValid: false,
      documentTypes: [],
      selectedTypes: '*',
      disabled: false,
      hasFileUploaded: false
    }
  },
  methods: {
    resetForm() {
      this.$refs.documentForm.reset()
      this.form = {
        studentId: null,
        studentDocumentTypeId: null,
        description: null,
        document: null
      }
      this.disabled = false
    },
    closeDialog() {
      this.$emit('close')
      this.resetForm()
    },
    selectType(item) {
      const selectedDocumentType = this.documentTypes.filter(i => i.id == item)
      this.selectedTypes = selectedDocumentType[0].allowedExtensions
    },
    validateForm() {
      const isValid = this.$refs.documentForm.validate()

      if (this.documentFormValid && isValid) {
        this.item ? this.$emit('update', this.form) : this.$emit('save', this.form)
      }
    },
    async fetchStudentDocumentTypes() {
      this.documentTypes = await API.fetchStudentDocumentTypes()
    },
    removeFileInput() {
      this.hasFileUploaded = false
    }
  },
  created() {
    this.fetchStudentDocumentTypes()
  }
}
</script>
